import type { Theme } from "theme-ui";

import { getMostReadable, responsive } from "../helpers";

const theme: Theme = {
  config: {
    useLocalStorage: false,
  },
  space: [
    "0px",
    "4px",
    "8px",
    "16px",
    "32px",
    "64px",
    "96px",
    "128px",
    "256px",
    "512px",
  ],
  fonts: {
    body: "brother-1816, sans-serif",
    heading: "brother-1816, sans-serif",
    subHeading: "Libre Baskerville, serif",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 800,
    subHeading: 400,
    bold: 700,
    extraBold: 800,
  },
  lineHeights: {
    body: 1.65,
    heading: 1.25,
  },
  colors: {
    text: "#000",
    background: "#f7f7f7",
    primary: "#000",
    secondary: "#FFE955",
    muted: "#f7f7f7",
    // ui
    success: "#2A8536",
    error: "#FF2E2E",
  },
  breakpoints: ["640px", "768px", "1024px", "1280px", "1536px"],
  sizes: {
    container: "100%",
  },
  radii: {
    maximum: "99999px",
    medium: "30px",
    default: "22px",
    small: "10px",
  },
  shadows: {
    default: "0 6px 24px rgba(0,0,0,.1)",
    higher: "0 6px 36px rgba(0,0,0,.3)",
  },
  layout: {
    container: {
      maxWidth: responsive({
        xs: "100%",
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        xxl: "1536px",
      }),
      paddingLeft: responsive({ xs: 3, md: 4, xl: 5, xxl: 7 }),
      paddingRight: responsive({ xs: 3, md: 4, xl: 5, xxl: 7 }),
      width: "100%",
    },
  },
  links: {
    nav: {
      fontWeight: "body",
    },
  },
  buttons: {
    primary: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "primary",
      textDecoration: "none",
      borderRadius: "maximum",
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 4,
      paddingRight: 4,
      textTransform: "uppercase",
      color: "#fff",
      letterSpacing: "2px",
      textAlign: "center",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "primary",
      transition: "all .3s",
      cursor: "pointer",
      "&:hover, &:focus": {
        backgroundColor: "transparent",
        color: "primary",
      },
      "&:after": {
        content: '"→"',
        marginLeft: 2,
      },
    },
    secondary: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "primary",
      textDecoration: "none",
      borderRadius: "maximum",
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 4,
      paddingRight: 4,
      textTransform: "uppercase",
      color: "primary",
      letterSpacing: "2px",
      textAlign: "center",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "transparent",
      transition: "all .3s",
      cursor: "pointer",
      "&:hover, &:focus": {
        backgroundColor: "primary",
        color: "#fff",
      },
      "&:after": {
        content: '"→"',
        marginLeft: 2,
      },
    },
    reset: {
      border: "none",
      margin: 0,
      padding: 0,
      width: "auto",
      overflow: "visible",
      background: "transparent",
      color: "inherit",
      font: "inherit",
      lineHeight: "normal",
      cursor: "pointer",
      textAlign: "inherit",
      WebkitAppearance: "none",
    },
  },
  forms: {
    label: {
      fontWeight: "bold",
      paddingLeft: "1rem",
      textTransform: "uppercase",
    },
    input: {
      border: (theme: Theme) =>
        `2px solid ${getMostReadable(
          theme.rawColors?.background
        ).toHexString()}`,
      background: "transparent",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderRadius: "2rem",
      padding: ".75rem 1rem",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: responsive({ xs: 1, md: 2 }),
      textRendering: "geometricPrecision",
      transition: "all .5s",
      transitionProperty: "color, border, background",
      "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover":
        {
          textFillColor: (theme: Theme) =>
            `${getMostReadable(
              theme.rawColors?.background
            ).toHexString()} !important`,
        },
      "&:focus": {
        outline: "none",
      },
    },
    textarea: {
      border: (theme: Theme) =>
        `2px solid ${getMostReadable(
          theme.rawColors?.background
        ).toHexString()}`,
      background: "transparent",
      color: (theme: Theme) =>
        getMostReadable(theme.rawColors?.background).toHexString(),
      borderRadius: "2rem",
      padding: ".75rem 1rem",
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: responsive({ xs: 1, md: 2 }),
      textRendering: "geometricPrecision",
      resize: "none",
      transition: "all .5s",
      transitionProperty: "color, border, background",
      "&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover":
        {
          textFillColor: (theme: Theme) =>
            `${getMostReadable(
              theme.rawColors?.background
            ).toHexString()} !important`,
        },
      "&:focus": {
        outline: "none",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: 2,
      textRendering: "geometricPrecision",
      overflow: "hidden",
      overflowY: "scroll",
      MozOsxFontSmoothing: "grayscale",
      WebkitFontSmoothing: "antialiased",
      textSizeAdjust: "100%",
    },
    h1: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 5,
      marginTop: 0,
      marginBottom: 0,
    },
    h2: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 4,
      marginTop: 0,
      marginBottom: 0,
    },
    h3: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 3,
      marginTop: 0,
      marginBottom: 0,
    },
    h4: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 2,
      marginTop: 0,
      marginBottom: 0,
    },
    h5: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 1,
      marginTop: 0,
      marginBottom: 0,
    },
    h6: {
      color: "text",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      fontSize: 0,
      marginTop: 0,
      marginBottom: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
      paddingTop: 0,
      marginBottom: "1.65em",
      breakInside: "avoid",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    a: {
      color: "primary",
    },
    img: {
      maxWidth: "100%",
      display: "block",
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
      marginTop: "2em",
      marginBottom: "2em",
      "&:last-child": {
        marginBottom: 0,
      },
      li: {
        position: "relative",
        paddingLeft: "2.5ex",
        marginBottom: "1em",
        "&:last-child": {
          marginBottom: 0,
        },
        "&:before": {
          content: '"—"',
          position: "absolute",
          left: 0,
        },
      },
    },
    ol: {
      marginTop: "2em",
      marginBottom: "2em",
      "&:last-child": {
        marginBottom: 0,
      },
      li: {
        marginBottom: "1em",
        "&:last-child": {
          marginBottom: 0,
        },
      },
    },
    pre: {
      whiteSpace: "pre-wrap",
      margin: 0,
      marginTop: "2em",
      marginBottom: "2em",
      padding: responsive({ xs: 3, md: 4 }),
      backgroundColor: "muted",
      borderRadius: "default",
      "&:last-child": {
        marginBottom: 0,
      },
    },
    blockquote: {
      borderLeftColor: "secondary",
      borderLeftStyle: "solid",
      borderLeftWidth: "3px",
      fontSize: "1.25em",
      fontFamily: "subHeading",
      marginTop: "2em",
      marginBottom: "2em",
      marginX: responsive({ xs: 2, sm: 4, lg: 6 }),
      padding: 0,
      paddingLeft: 4,
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "-4px",
        height: "2em",
        backgroundColor: "#fff",
        width: "5px",
        marginTop: "-2em",
      },
      "&:after": {
        color: "secondary",
        content: '"“"',
        fontSize: "4em",
        fontStyle: "normal",
        fontFamily: 'Georgia, Times, "Times New Roman", serif',
        left: "-0.5em",
        lineHeight: "1",
        marginTop: "-0.5em",
        position: "absolute",
        textAlign: "center",
        top: "50%",
        width: "1em",
      },
      p: {
        fontFamily: "subHeading",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
};

export default theme;
