import { getPreviewImageNode } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";
import type { WidgetShape } from "../types";

export const getPreview: GetPreview<
  "PageContentWidgetImageGrid",
  "ContentWidgetImageGrid"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetImageGrid",
    grid: widget.grid?.map((row) => {
      return {
        ...row,
        __typename: "ContentWidgetImageGridGrid",
        imageNodes: row?.imageNodes?.map((imageNode) => {
          return getPreviewImageNode<
            NonNullable<
              NonNullable<
                NonNullable<
                  WidgetShape<"ContentWidgetImageGrid">["grid"]
                >[number]
              >["imageNodes"]
            >[number]
          >(imageNode);
        }),
      };
    }),
  };
};
