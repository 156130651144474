import {
  type FC,
  type ReactNode,
  createContext,
  memo,
  useContext,
} from "react";
import { useTina } from "tinacms/dist/react";

import type { PageQuery } from "../../.tina/__generated__/types";
import { PageDocument } from "../../.tina/__generated__/types";
import { widgetPreview } from "./widgetPreview";

type Context = {
  relativePath: string;
};

export const TinaCMSDataContext = createContext<Context>({
  relativePath: "",
});

export const PreviewProvider: FC<{
  children: ReactNode;
  collection: string;
  relativePath: string;
}> = memo(({ children, relativePath }) => {
  const resolvedRelativePath =
    relativePath || global.localStorage?.getItem("tinaCMSRelativePath") || "";

  return (
    <TinaCMSDataContext.Provider
      value={{
        relativePath: resolvedRelativePath,
      }}
    >
      {children}
    </TinaCMSDataContext.Provider>
  );
});

export const useTinaCMSData = <T,>(): T => {
  const { relativePath } = useContext(TinaCMSDataContext);

  const { data } = useTina<PageQuery>({
    data: {
      page: {
        __typename: "Page",
        id: relativePath,
        title: "",
        path: "",
        _sys: {
          filename: "",
          basename: "",
          breadcrumbs: [""],
          path: "",
          relativePath: "",
          extension: "",
        },
      },
    },
    variables: { relativePath },
    query: PageDocument,
  });

  const enhancedTinaData = data.page?.content
    ? {
        ...data,
        page: {
          ...data?.page,
          content: data?.page?.content?.map((widget) => {
            if (widget?.__typename) {
              return widgetPreview[widget?.__typename]?.(widget);
            }

            console.error("Missing preview", widget);

            return widget;
          }),
        },
      }
    : null;

  return enhancedTinaData as T;
};
