import {
  TinyColor,
  type WCAG2FallbackParms,
  mostReadable,
} from "@ctrl/tinycolor";
import type { Property } from "csstype";
import type { ColorModesScale } from "theme-ui";

const UIColors = {
  dark: new TinyColor("#000"),
  light: new TinyColor("#fff"),
  darkAlt: new TinyColor("#111"),
  lightAlt: new TinyColor("#eee"),
};

const COLOR_DISTANCE_THRESHOLD = 20;

export const responsive = <T>(
  breakpoints: {
    [key in "xs" | "sm" | "md" | "lg" | "xl" | "xxl"]?: T;
  }
): (T | undefined)[] => {
  return [
    breakpoints.xs,
    breakpoints.sm,
    breakpoints.md,
    breakpoints.lg,
    breakpoints.xl,
    breakpoints.xxl,
  ];
};

export const getPathWithAnchor = (
  path: string | null | undefined,
  anchor: string | null | undefined
): string => {
  return `${typeof path === "string" ? path : ""}${anchor ? `#${anchor}` : ""}`;
};

export const getTinyColor = (color: unknown): TinyColor =>
  typeof color === "string" ? new TinyColor(color) : UIColors.dark;

const rgb2lab = (RGBInput: [number, number, number]): number[] => {
  const rgb = [0, 0, 0];
  const xyz = [0, 0, 0];
  const Lab = [0, 0, 0];

  for (let i = 0; i < RGBInput.length; i++) {
    let value = RGBInput[i] / 255;

    if (value > 0.04045) {
      value = ((value + 0.055) / 1.055) ** 2.4;
    } else {
      value = value / 12.92;
    }

    rgb[i] = value * 100;
  }

  xyz[0] = (rgb[0] * 0.4124 + rgb[1] * 0.3576 + rgb[2] * 0.1805) / 95.047; // ref_X =  95.047   Observer= 2°, Illuminant= D65
  xyz[1] = (rgb[0] * 0.2126 + rgb[1] * 0.7152 + rgb[2] * 0.0722) / 100.0; // ref_Y = 100.000
  xyz[2] = (rgb[0] * 0.0193 + rgb[1] * 0.1192 + rgb[2] * 0.9505) / 108.883; // ref_Z = 108.883

  for (let i = 0; i < 3; i++) {
    let value = xyz[i];
    if (value > 0.008856) {
      value = value ** (1 / 3);
    } else {
      value = 7.787 * value + 16 / 116;
    }
    xyz[i] = value;
  }

  Lab[0] = Number.parseFloat((116 * xyz[1] - 16).toFixed(3));
  Lab[1] = Number.parseFloat((500 * (xyz[0] - xyz[1])).toFixed(3));
  Lab[2] = Number.parseFloat((200 * (xyz[1] - xyz[2])).toFixed(3));

  return Lab;
};

const getColorDistance = (
  color1: Property.Color,
  color2: Property.Color
): number => {
  const color1RGB = getTinyColor(color1).toRgb();
  const color2RGB = getTinyColor(color2).toRgb();
  const color1LAB = rgb2lab([color1RGB.r, color1RGB.g, color1RGB.b]);
  const color2LAB = rgb2lab([color2RGB.r, color2RGB.g, color2RGB.b]);

  return Math.sqrt(
    (color1LAB[0] - color2LAB[0]) ** 2 +
      (color1LAB[1] - color2LAB[1]) ** 2 +
      (color1LAB[2] - color2LAB[2]) ** 2
  );
};

type ColorValue = Property.Color | ColorModesScale["background"];

export const getMostVisible = (
  baseColor: ColorValue,
  colors: ColorValue[],
  WCAG2FallbackParms: WCAG2FallbackParms | undefined = {
    level: "AA",
    size: "large",
  },
  readableColorsFallback?: ColorValue[] | undefined,
  colorDistanceThreshold?: number | undefined
): TinyColor => {
  if (typeof baseColor !== "string") {
    return UIColors.dark;
  }

  const visibleColor = colors.find(
    (color) =>
      (typeof color === "string"
        ? getColorDistance(baseColor, color)
        : (UIColors.dark as unknown as number)) >=
      (colorDistanceThreshold || COLOR_DISTANCE_THRESHOLD)
  );

  return visibleColor
    ? getTinyColor(visibleColor)
    : getMostReadable(baseColor, readableColorsFallback, WCAG2FallbackParms);
};

export const getMostReadable = (
  baseColor: ColorValue,
  colorList?: ColorValue[],
  WCAG2FallbackParms?: WCAG2FallbackParms
): TinyColor => {
  if (typeof baseColor !== "string") {
    return UIColors.dark;
  }

  const _colorList: Property.Color[] | undefined = [];

  colorList?.forEach((color) => {
    if (typeof color === "string") {
      _colorList?.push(color);
    }
  });

  return (
    mostReadable(
      baseColor,
      _colorList?.length ? _colorList : [UIColors.light, UIColors.dark],
      {
        ...WCAG2FallbackParms,
        includeFallbackColors: true,
      }
    ) || UIColors.dark
  );
};
