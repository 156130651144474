import type { GatsbyBrowser, WrapPageElementBrowserArgs } from "gatsby";
import type React from "react";
import { CookiesProvider } from "react-cookie";

import { PreviewProvider } from "./src/preview/Provider";

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<unknown>): React.ReactNode => {
  return (
    <CookiesProvider>
      <PreviewProvider
        collection={
          ((props.pageContext.collection || "") as string).split("-")[0]
        }
        relativePath={props.pageContext.relativePath as string}
      >
        {element}
      </PreviewProvider>
    </CookiesProvider>
  );
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  // Track page view
  if (window.Analytics !== null) {
    window.Analytics?.page();
  }
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps,
}) => {
  const { shouldUpdateScroll = true } = routerProps.location.state || {};
  return shouldUpdateScroll;
};
