import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetContactForm",
  "ContentWidgetContactForm"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetContactForm",
  };
};
