import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetAnchor",
  "ContentWidgetAnchor"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetAnchor",
  };
};
