import { getPreviewImageNode } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";
import type { WidgetShape } from "../types";

export const getPreview: GetPreview<
  "PageContentWidgetBannerImage",
  "ContentWidgetBannerImage"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetBannerImage",
    image:
      getPreviewImageNode<WidgetShape<"ContentWidgetBannerImage">>(widget)
        ?.image,
  };
};
