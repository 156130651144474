import { getPreviewImageNode } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetParagraphStack",
  "ContentWidgetParagraphStack"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetParagraphStack",
    imageNode: getPreviewImageNode(widget?.imageNode),
    specs: widget.specs?.map((spec) => {
      return {
        ...spec,
        __typename: "ContentWidgetParagraphStackSpecs",
        content: {
          ast: spec?.content,
        },
      };
    }),
  };
};
