import {
  getPreviewImageNode,
  getPreviewLinkNodes,
  replacer,
} from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetSkills",
  "ContentWidgetSkills"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetSkills",
    skills: widget.skills?.map((skill) => ({
      ...skill,
      __typename: "ContentWidgetSkillsSkills",
      description: {
        ast: skill?.description,
      },
      imageNode: getPreviewImageNode(skill?.imageNode),
    })),
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
  };
};
