import { getPreviewLinkNodes, replacer } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetProjectHeading",
  "ContentWidgetProjectHeading"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetProjectHeading",
    content: {
      ast: widget.content,
    },
    tags: widget.tags?.map((tagNode) => ({
      tag: {
        id: tagNode?.tag?.id ?? "",
        childTag: {
          tag: tagNode?.tag?.tag,
          color: tagNode?.tag?.color,
        },
      },
    })),
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
  };
};
