import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetHomeHeading",
  "ContentWidgetHomeHeading"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetHomeHeading",
  };
};
