import { getPreview as ContentWidgetAnchor } from "../widgets/WidgetAnchor/preview";
import { getPreview as ContentWidgetBannerImage } from "../widgets/WidgetBannerImage/preview";
import { getPreview as ContentWidgetBox } from "../widgets/WidgetBox/preview";
import { getPreview as ContentWidgetCards } from "../widgets/WidgetCards/preview";
import { getPreview as ContentWidgetContactForm } from "../widgets/WidgetContactForm/preview";
import { getPreview as ContentWidgetCustomContent } from "../widgets/WidgetCustomContent/preview";
import { getPreview as ContentWidgetHeading } from "../widgets/WidgetHeading/preview";
import { getPreview as ContentWidgetHomeHeading } from "../widgets/WidgetHomeHeading/preview";
import { getPreview as ContentWidgetImageGrid } from "../widgets/WidgetImageGrid/preview";
import { getPreview as ContentWidgetParagraphGrid } from "../widgets/WidgetParagraphGrid/preview";
import { getPreview as ContentWidgetParagraphStack } from "../widgets/WidgetParagraphStack/preview";
import { getPreview as ContentWidgetProjectHeading } from "../widgets/WidgetProjectHeading/preview";
import { getPreview as ContentWidgetProjects } from "../widgets/WidgetProjects/preview";
import { getPreview as ContentWidgetSkills } from "../widgets/WidgetSkills/preview";
import type { TinaCMSWidgetName } from "./types";

// biome-ignore lint/suspicious/noExplicitAny: any is used to match the type of the widget
export const widgetPreview: Record<TinaCMSWidgetName, any> = {
  PageContentWidgetBannerImage: ContentWidgetBannerImage,
  PageContentWidgetBox: ContentWidgetBox,
  PageContentWidgetCards: ContentWidgetCards,
  PageContentWidgetContactForm: ContentWidgetContactForm,
  PageContentWidgetCustomContent: ContentWidgetCustomContent,
  PageContentWidgetHeading: ContentWidgetHeading,
  PageContentWidgetHomeHeading: ContentWidgetHomeHeading,
  PageContentWidgetImageGrid: ContentWidgetImageGrid,
  PageContentWidgetParagraphGrid: ContentWidgetParagraphGrid,
  PageContentWidgetParagraphStack: ContentWidgetParagraphStack,
  PageContentWidgetProjectHeading: ContentWidgetProjectHeading,
  PageContentWidgetProjects: ContentWidgetProjects,
  PageContentWidgetSkills: ContentWidgetSkills,
  PageContentWidgetAnchor: ContentWidgetAnchor,
};
