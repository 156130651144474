import type { FileLinkNode, InternalLinkNode, LinkNode } from "./types";

export const replacer = (__typename: string) =>
  __typename.replace("PageContent", "Content");

export const getPreviewImageNode = <Output>(
  // biome-ignore lint/suspicious/noExplicitAny: any
  imageNode: any,
  // biome-ignore lint/complexity/noBannedTypes: This is a helper function
  alias?: Output extends {} ? (keyof Output)[] : never
): Output | null => {
  if (typeof imageNode !== "object") {
    return null;
  }

  const imageNodePreview = {
    ...imageNode,
  } as Output;
  (alias || ["image"]).forEach((key) => {
    // @ts-expect-error
    imageNodePreview[key] = {
      __typename: "File",
      publicURL:
        imageNode && "image" in imageNode ? imageNode?.image : undefined,
    };
  });

  return imageNodePreview;
};

export const getPreviewLinkNodes = <Output>(
  // biome-ignore lint/suspicious/noExplicitAny: any
  linkNodes: any[] | null | undefined,
  replacer: (__typename: string) => string
): Output => {
  return linkNodes?.map((_linkNode) => {
    const linkNode = _linkNode as LinkNode;

    const genericTypeName =
      linkNode?.__typename.match(/LinkNodes([A-Za-z]+)/)?.[1];

    switch (genericTypeName) {
      case "InternalLink": {
        return {
          ...linkNode,
          __typename: replacer(linkNode.__typename),
          internalLink: {
            childPage: {
              path: `/${(linkNode as InternalLinkNode).internalLink?.path}`,
            },
          },
        };
      }
      case "ExternalLink": {
        return {
          ...linkNode,
          __typename: replacer(linkNode.__typename),
        };
      }
      case "FileLink": {
        return {
          ...linkNode,
          __typename: replacer(linkNode.__typename),
          fileLink: {
            publicURL: (linkNode as FileLinkNode).fileLink,
          },
        };
      }
      default: {
        return linkNode;
      }
    }
  }) as Output;
};
