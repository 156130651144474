import {
  getPreviewImageNode,
  getPreviewLinkNodes,
  replacer,
} from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetHeading",
  "ContentWidgetHeading"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetHeading",
    content: {
      ast: widget.content,
    },
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
    imageNode: getPreviewImageNode(widget.imageNode),
  };
};
