import { getPreviewImageNode } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetParagraphGrid",
  "ContentWidgetParagraphGrid"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetParagraphGrid",
    grid: widget.grid?.map((row) => {
      return {
        ...row,
        __typename: "ContentWidgetParagraphGridGrid",
        content: {
          ast: row?.content,
        },
        imageNode: getPreviewImageNode(row?.imageNode),
      };
    }),
  };
};
