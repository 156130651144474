import { getPreviewImageNode } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetCards",
  "ContentWidgetCards"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetCards",
    cards: widget.cards?.map((card) => ({
      ...card,
      __typename: "ContentWidgetCardsCards",
      imageNode: getPreviewImageNode(card?.imageNode),
    })),
  };
};
