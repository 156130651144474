import { getPreviewLinkNodes, replacer } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetBox",
  "ContentWidgetBox"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetBox",
    content: {
      ast: widget.content,
    },
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
  };
};
