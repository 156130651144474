import { getPreviewLinkNodes, replacer } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetCustomContent",
  "ContentWidgetCustomContent"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetCustomContent",
    content: {
      ast: widget.content,
    },
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
  };
};
