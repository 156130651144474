import { getPreviewLinkNodes, replacer } from "../../preview/helpers";
import type { GetPreview } from "../../preview/types";

export const getPreview: GetPreview<
  "PageContentWidgetProjects",
  "ContentWidgetProjects"
> = (widget) => {
  return {
    ...widget,
    __typename: "ContentWidgetProjects",
    intro: {
      ast: widget.intro,
    },
    linkNodes: getPreviewLinkNodes(widget.linkNodes, replacer),
  };
};
